exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benessere-js": () => import("./../../../src/pages/benessere.js" /* webpackChunkName: "component---src-pages-benessere-js" */),
  "component---src-pages-calendario-js": () => import("./../../../src/pages/calendario.js" /* webpackChunkName: "component---src-pages-calendario-js" */),
  "component---src-pages-contattaci-js": () => import("./../../../src/pages/contattaci.js" /* webpackChunkName: "component---src-pages-contattaci-js" */),
  "component---src-pages-corsi-arti-marziali-js": () => import("./../../../src/pages/corsi-arti-marziali.js" /* webpackChunkName: "component---src-pages-corsi-arti-marziali-js" */),
  "component---src-pages-corsi-js": () => import("./../../../src/pages/corsi.js" /* webpackChunkName: "component---src-pages-corsi-js" */),
  "component---src-pages-fase-activity-riapertura-corsi-js": () => import("./../../../src/pages/fase-activity-riapertura-corsi.js" /* webpackChunkName: "component---src-pages-fase-activity-riapertura-corsi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-perche-noi-js": () => import("./../../../src/pages/perche-noi.js" /* webpackChunkName: "component---src-pages-perche-noi-js" */),
  "component---src-pages-tariffe-js": () => import("./../../../src/pages/tariffe.js" /* webpackChunkName: "component---src-pages-tariffe-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */)
}

